import { deleteRequest, getRequest } from "@/libs/axios";

// 查询操作日志列表
export function list(query) {
  return getRequest('/yethan/monitor/operlog/list', query);
}

// 删除操作日志
export function delOperlog(operId) {
  return deleteRequest('/yethan/monitor/operlog/' + operId, 'delete');
}

